.section-center-text {

    & .page-content {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6,
        & p,
        & blockquote {
            width: 100%;
            max-width: 730px;
        }

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
            margin: 46px auto 16px;
        }

        & p,
        & blockquote {
            margin: 16px auto;
        }

        & img {
            display: block;
            width: 100%;
            height: auto;
            margin: 40px auto;
            /* border: 2px solid #000; */
        }

        & table {
            margin: 2rem auto;

            & td {
                padding: 12px;
            }
        }

        & blockquote {
            margin: 2rem auto;
            padding: 12px 12px 12px 40px;
            border-left: 2px solid var(--color-ocean);
        }

        & .text-big {
            font-size: 1.2em;
        }

        & .pre-head {
            text-transform: uppercase;
            font-size: 0.9em;
            letter-spacing: 1.2px;
        }
    }
}

@media (--viewport-md-min) {
    .section-center-text {

        & .page-content {

            & img {
                position: relative;
                width: calc(100% + 130px);
                margin: 80px auto;
                margin-left: -65px;
            }
        }
    }
}

@media (--viewport-lg-min) {
    .section-center-text {

        & .page-content {

            & img {
                width: calc(100% + 260px);
                margin: 80px auto;
                margin-left: -130px;
            }
        }
    }
}