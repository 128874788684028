.header-vrienden-spoorpark {

    & .hvs-wrap {
        position: relative;

        & .logo {
            position: absolute;
            z-index: 9;
            top: -32px;
            left: 15px;

            & img {
                width: 140px;
            }
        }

        & .hvs-balk {
            background-color: #6a5d4d;

            & .content {
                display: flex;
                justify-content: flex-end;
                max-width: 1280px;
                margin: 0 auto;
                padding: 8px 15px;
                color: #fff;
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;

                & a {
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        opacity: .6;
                    }
                }

                & ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    display: flex;

                    & li {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1340px) {
    .header-vrienden-spoorpark {

        & .hvs-wrap {
            position: relative;
    
            & .logo {
                position: absolute;
                z-index: 9;
                top: -32px;
                left: calc((100% - 1280px) / 2 );

                & img {
                    width: 100%;
                }
            }
        }
    }
}