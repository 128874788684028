body {
  font-family: 'TilburgsAns', Arial, Helvetica, sans-serif;
}

p {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.TableNoBorder td {
    border: none;
}

/*@font-face {
    font-family: 'TilburgsAns';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/TilburgsAns-Light.woff') format('woff'), url('../fonts/TilburgsAns-Light.eot') format('eot');
}

@font-face {
    font-family: 'TilburgsAns';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/TilburgsAns-Regular.woff') format('woff'), url('../fonts/TilburgsAns-Regular.eot') format('eot');
}

@font-face {
    font-family: 'TilburgsAns';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/TilburgsAns-Bold.woff') format('woff'), url('../fonts/TilburgsAns-Bold.eot') format('eot');
}*/

.jumbotron {
    margin-top: 0;
    text-align: center;
}

td {
    border: 1px solid #d2d2d2;
}

.unwidth {
    max-width: unset !important;
}

.gebruiker-overzicht-wrap {
    display: flex;
}

.gebruiker-overzicht-wrap .gow-tile-wrap {
    width: 100%;
    padding: 0 12px;
    cursor: pointer;
}

.gebruiker-overzicht-wrap .gow-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px #d2d2d2;
    width: 100%;
    padding: 20px;
}

.gebruiker-overzicht-wrap .gow-icon {
    font-size: 48px;
}

.gebruiker-overzicht-wrap .gow-title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.gebruiker-overzicht-wrap a {
    color: inherit;
    text-decoration: none;
}

.gebruiker-overzicht-wrap a:hover {
    color: inherit;
    text-decoration: underline;
}

