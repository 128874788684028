.hero-vrienden-spoorpark {

    & .hvs-wrap {
        position: relative;
        overflow: hidden;

        & .background-image {
            position: absolute;
            height: 100%;
            width: 100%;

            & img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }

        & .hvs-title {
            position: relative;
            z-index: 2;
            padding: 100px 0 120px;
            max-width: 1280px;
            margin: 0 auto;

            & h1 {
                color: #fff;
                text-align: center;
                text-transform: uppercase;
                text-shadow: 0em 0.1em 0.1em #47402e;
            }
        }

        & .hvs-divider {
            position: absolute;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTEyODAgMy40QzEwNTAuNTkgMTggMTAxOS40IDg0Ljg5IDczNC40MiA4NC44OWMtMzIwIDAtMzIwLTg0LjMtNjQwLTg0LjNDNTkuNC41OSAyOC4yIDEuNiAwIDMuNFYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAyNC4zMWM0My40Ni01LjY5IDk0LjU2LTkuMjUgMTU4LjQyLTkuMjUgMzIwIDAgMzIwIDg5LjI0IDY0MCA4OS4yNCAyNTYuMTMgMCAzMDcuMjgtNTcuMTYgNDgxLjU4LTgwVjE0MEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDUxLjc2Yy0yMDEgMTIuNDktMjQyLjQzIDUzLjQtNTEzLjU4IDUzLjQtMzIwIDAtMzIwLTU3LTY0MC01Ny00OC44NS4wMS05MC4yMSAxLjM1LTEyNi40MiAzLjZWMTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg==);
            background-size: 100% 50px;
            bottom: 0;
            height: 50px;
            z-index: 10;
            width: 100%;
        }
    }
}

