.map_box_container {
    position: relative;
    height: 320px;
    width: 100%;
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .map_box_container {
        height: 550px;
        width: calc(100% + 200px);
        margin: 0 -100px 30px;
    }
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

#map canvas {
    outline: none;
}

.legend {
    margin: 20px 0;
}

.legend-title {
    margin-bottom: 5px;
}

.legend-item {
    display: inline-block;
    margin-right: 20px;
}

.square {
    padding: 0 10px;
    margin-right: 7.5px;
}

div.blur {
    filter: blur(10px)
}

div.animated {
    -webkit-animation: filter-animation 1s;
}

@-webkit-keyframes filter-animation {
    0% {
        -webkit-filter: blur(10px)
    }

    50% {
        -webkit-filter: blur(5px)
    }

    100% {
        -webkit-filter: blur(0)
    }
}