/**
 * Fonts
 */

 @font-face {
    font-family: 'TilburgsAns';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: 
        url('../fonts/TilburgsAns-Light.woff') format('woff'),
        url('../fonts/TilburgsAns-Light.eot') format('eot');
}

@font-face {
    font-family: 'TilburgsAns';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: 
        url('../fonts/TilburgsAns-Regular.woff') format('woff'),
        url('../fonts/TilburgsAns-Regular.eot') format('eot');
}

@font-face {
    font-family: 'TilburgsAns';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: 
        url('../fonts/TilburgsAns-Bold.woff') format('woff'),
        url('../fonts/TilburgsAns-Bold.eot') format('eot');
}