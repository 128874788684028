.image-text {
    display: flex;
    flex-direction: column;

    &.reverse {
        flex-direction: column;
    }

    &.block {

        & .content {
            padding: 30px;
        }
    
        & .image {
            align-self: unset;
    
            & figure {
                height: 100%;
    
                & img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    & .content {
        align-self: center;
        width: 100%;
        padding: 0px;
    }
    
    & .image {
        align-self: center;
        width: 100%;
    }
}

@media (--viewport-md-min) {
    .image-text {
        flex-direction: row;
    
        &.reverse {
            flex-direction: row-reverse;
        }
    
        &.block {
    
            & .content {
                padding: 100px;
            }
        }
    
        & .content {
            width: 50%;
            padding: 60px;
        }
        
        & .image {
            width: 50%;
        }
    }
}