.section-center-text .page-content {

    & .spotpicker-wrap {
        margin-top: 40px;

        & canvas {
            background-color: white;
            display: block;
            width: 100%;
        }
    
        & h3 {
            margin: 8px 0;
        }
    
        & ul {
            padding: 0;
            margin: 8px 0;
            display: flex;
            list-style-type: none;
    
            & li {
                margin-right: 16px;
    
                &.beschikbaar::before {
                    display: inline-block;
                    content: '';
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    background-color: green;
                }
    
                &.gereserveerd::before {
                    display: inline-block;
                    content: '';
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    background-color: orange;
                }
    
                &.geadopteerd::before {
                    display: inline-block;
                    content: '';
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    background-color: red;
                }
                &.gereserveerd::before {
                    display: inline-block;
                    content: '';
                    width: 16px;
                    height: 16px;
                    background-color: orange;
                }
            }
        }
    }

    & .spotpicker-select-form {

        & .ssf-title,
        & p,
        & button {
            text-align: center;
        }
    }
}