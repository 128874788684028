.checkout-page-section {

    & .cps-wrap {
        display: flex;
        flex-direction: column-reverse;

        & .form {
            width: 100%;
            padding-right: 60px;
        }

        & .order {

            & .order-overview {
                border: 1px solid #ebebeb;
                padding: 15px;

                & h3 {
                    margin: 8px 0;
                    font-size: 25px;
                }

                & table {
                    width: 100%;
                    border-spacing: 0;
                    font-size: 16px;

                    & td {
                        border-bottom: 1px solid #ebebeb;
                        padding: 8px 0;

                        &:first-child {
                            font-weight: 700;
                        }
                    }

                    & .totaal {
                        font-size: 18px;

                        & td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .checkout-page-section {

        & .cps-wrap {
            flex-direction: row;

            & .order {
                min-width: 400px;
                
                & .order-overview {
                    position: sticky;
                    top: 80px;
                    padding: 30px;
                }
            }
        }
    }
}