/**
 * Base
 */
:root {
    --site-background-color: var(--site-background-color, #fff);
}

/* Box sizing */
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

/* Body */
body {
    background-color: var(--site-background-color);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    line-height: 1.7;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;

    @media (--viewport-lg-min) {
        font-size: 18px;
    }
}

/* Links */
a {
    color: var(--color-link, #0066CB);
    text-decoration: underline;
    text-decoration-skip-ink: auto;

    &:hover,
    &:focus {
        color: #006BFF;
    }
}

/* Horizontal ruler */
hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 30px 0;
}

/* Image handling */
figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }

    &.contain-width img {
        width: auto;
    }
}

/* Form fix for umbraco */
form {

    & fieldset {
        border: none;
        padding: 0;
    }
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--heading-font-family);
    font-weight: 600;
}

/* Background settings */
.background-section {
    padding: 40px 0;
    margin: 0;
}

@media (--viewport-md-min) {
    .background-section {
        padding: 80px 0;
    }    
}

/* List Check */

ul.list-check {
    list-style: none;
    padding: 0;

    & li {
        margin: 4px 0;
        padding-left: 24px;
        position: relative;
        
        &:before {
            content: " ";
            background-size: cover;
            background-image: url("/dist/images/list-check.svg");
            width: 1em;
            height: 1em;
            position: absolute;
            left: 0;
            top: 4px;
        }
    }
}

/* 
    input spotpicker
*/
.ssf-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 -8px;
}

.abbo-container {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    margin-top: 30px;
    margin: 0 8px;

    & input[type=radio] {
        width: auto;
        box-sizing: border-box;
        padding: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;
        bottom: 0;
        left: 50%;

        &:checked + .abbo-label .abbo-content {
            border: 2px orange solid;
            /* opacity: 1; */

            &::after {
                position: absolute;
                content: '';
                width: 0; 
                height: 0; 
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid orange;
                bottom: 0;
                left: calc(50% - 12px);
            }
        }
    }

    & .abbo-label {
        display: block;
        color: #000;
        text-align: center;
        /* height: 100%; */

        & .abbo-content {
            position: relative;
            background-color: #444118;
            border: 0px #fff solid;
            padding: 40px 20px;
            cursor: pointer;
            /* border: 2px #99b6b1 solid; */
            border-radius: 8px;
            /* height: 100%; */

            &.eenjaar { background-color: #8B8B6D; }
            &.tweejaar { background-color: #8B8F65; }
            &.driejaar { background-color: #444118; }

            & .top-label {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-0, -50%);
                width: 100%;
                display: block;

                & .inner-wrap {
                    font-family: var(--heading-font-family);
                    font-style: italic;
                    text-transform: uppercase;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 1px;
                    display: inline-block;
                    text-align: center;
                    background-color: #444118;
                    color: #fff;
                    padding: 6px 12px;
                    border-radius: 5px;
                    font-size: 15px;
                }
            }

            & span {
                display: block;
                color: #fff;

                &.weken {
                    font-family: var(--heading-font-family);
                    font-weight: 600;
                    /* font-size: 1.2em; */
                    font-size: 1.5em;
                    text-transform: uppercase;
                    /* color: var(--color-blue-light); */
                }

                &.abonnement {
                    /* text-transform: uppercase; */
                    font-size: 16px;
                    letter-spacing: 1px;
                    font-weight: 400;
                    /* margin: 0 0 16px; */
                    color: #fff;
                }

                /* &.kosten {
                    margin: 16px 0 0;
                    color: #fff;
                } */
            }

            &--meest-gekozen {
                position: relative;
                overflow: hidden;

                & .corner-ribbon {
                    font-family: var(--heading-font-family);
                    width: 200px;
                    background: #2c7;
                    position: absolute;
                    top: 25px;
                    left: -50px;
                    text-align: center;
                    font-size: 17px;
                    line-height: 42px;
                    /* letter-spacing: 1px; */
                    color: #fff;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    box-shadow: 0 0 3px rgba(0,0,0,.3);
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .ssf-wrap {
        margin: 0 -8px;
        flex-direction: row;
    }
}